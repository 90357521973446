.reasons {
  padding: 0 2rem;
  display: flex;
  gap: 2rem;
}

.left-r {
  /* flex: 1 1; */
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1rem;
  grid-auto-rows: 1fr;
}

.left-r > img {
  object-fit: cover;
}

.left-r > :nth-child(1) {
  width: 12rem;
  grid-row: 1/ 3;
  height: 28rem;
}

.left-r > :nth-child(2) {
  width: auto;
  height: 16rem;
  grid-column: 2/4;
}

.left-r > :nth-child(3) {
  grid-column: 2/3;
  grid-row: 2;
  width: 14rem;
}

.left-r > :nth-child(4) {
  grid-row: 2;
  grid-column: 3/4;
  width: 10rem;
  height: 11.2rem;
}

.right-r {
  flex: 1 1;
  gap: 1rem;
  display: flex;
  flex-direction: column;
}

.right-r > span {
  font-weight: bold;
  color: var(--orange);
}

.right-r > div {
  color: white;
  font-size: 3rem;
}

.details-r {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.details-r > div {
  display: flex;
  font-size: 1rem;
  gap: 1rem;
}

.details-r > div > img {
  width: 2rem;
  height: 2rem;
}

.partners-text {
  font-size: 1rem;
  color: var(--gray);
  font-weight: normal;
}

.partners {
  display: flex;
  gap: 1rem;
}

.partners > img {
  width: 2.5rem;
}

@media screen and (max-width: 768px) {
  .reasons {
    flex-direction: column;
  }

  .left-r {
    grid-auto-rows: auto;
    overflow: hidden;
  }
  .left-r > :nth-child(1) {
    width: 10rem;
    height: 30rem;
  }

  .left-r > :nth-child(2) {
    width: 29rem;
    height: 15rem;
  }

  .left-r > :nth-child(3) {
    width: 14rem;
    height: 14rem;
  }

  .left-r > :nth-child(4) {
    width: 14rem;
    height: 14rem;
  }
}

@media screen and (max-width: 411px) {
  .left-r > :nth-child(1) {
    width: 6rem;
    height: 15rem;
  }
  .left-r > :nth-child(2) {
    width: 13rem;
    height: 7.5rem;
  }

  .left-r > :nth-child(3) {
    width: 6rem;
    height: 6.5rem;
  }

  .left-r > :nth-child(4) {
    width: 6rem;
    height: 6.5rem;
  }
}

@media screen and (max-width: 450px) {
  .left-r {
    gap: 0.5rem;
  }
  .left-r > :nth-child(1) {
    width: 7rem;
    height: 17.4rem;
  }
  .left-r > :nth-child(2) {
    width: 16rem;
    height: 8.5rem;
  }

  .left-r > :nth-child(3) {
    width: 8rem;
    height: 8.5rem;
  }

  .left-r > :nth-child(4) {
    width: 7.4rem;
    height: 8.5rem;
  }

  @media screen and (max-width: 390px) {
    .left-r {
      gap: 1rem;
      position: relative;
      left: 0.6rem;
    }
    .left-r > :nth-child(1) {
      width: 8rem;
      height: 20rem;
    }
    .left-r > :nth-child(2) {
      width: 10rem;
      height: 9.5rem;
    }

    .left-r > :nth-child(3) {
      width: 10rem;
      height: 9.5rem;
    }

    .left-r > :nth-child(4) {
      display: none;
    }
  }
}
