.header {
  display: flex;
  justify-content: space-between;
  text-transform: capitalize;
}

.logo {
  width: 10rem;
  height: 3rem;
}

.header-menu {
  list-style-type: none;
  display: flex;
  gap: 2rem;
  color: #fff;
}

.header-menu > li:hover {
  color: var(--orange);
  cursor: pointer;
}

@media screen and (max-width: 768px) {
  .header > :nth-child(2) {
    position: fixed;
    right: 2rem;
    z-index: 98;
  }

  .header-menu {
    flex-direction: column;
    background: var(--appColor);
    padding: 1rem;
  }
  .barsBtn > img {
    width: 1.5rem;
    height: 1rem;
    background: var(--appColor);
    padding: 0.5rem;
    border-radius: 5px;
  }
}
