.testimonials {
  display: flex;
  gap: 1rem;
  padding: 0 2rem;
}

.left-t {
  display: flex;
  flex: 1 1;
  gap: 2rem;
  flex-direction: column;
  text-transform: uppercase;
  color: white;
}

.left-t :nth-child(1) {
  color: var(--orange);
  font-weight: bold;
}

.left-t :nth-child(2),
.left-t :nth-child(3) {
  font-weight: bold;
  font-size: 3rem;
}

.left-t :nth-child(4) {
  text-transform: none;
  text-align: justify;
  letter-spacing: 2px;
  line-height: 40px;
}

.right-t {
  flex: 1;
  position: relative;
}

.right-t > img {
  position: absolute;
  width: 17rem;
  height: 20rem;
  object-fit: cover;
  right: 8rem;
  top: 2rem;
}

.imageBorder {
  width: 17rem;
  height: 20rem;
  object-fit: cover;
  position: absolute;
  border: 4px solid var(--orange);
  right: 9rem;
  top: 1rem;
  z-index: -2;
}

.imageGradiante {
  width: 17rem;
  height: 20rem;
  object-fit: cover;
  position: absolute;
  background: var(--planCard);
  right: 7rem;
  top: 3rem;
  z-index: -1;
}

.arrows {
  display: flex;
  gap: 1rem;
  position: absolute;
  top: 19rem;
  left: 5rem;
}

.arrows > img {
  width: 1.5rem;
  height: 1.5rem;
}

@media screen and (max-width: 768px) {
  .testimonials {
    flex-direction: column;
  }

  .left-t > :nth-child(2),
  .left-t > :nth-child(3) {
    font-size: xx-large;
  }
  .right-t {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 2rem;
  }

  .right-t > div:nth-child(2),
  .right-t > div:nth-child(3) {
    display: none;
    position: relative;
  }

  .right-t > img {
    top: 0;
    right: 0;
    position: relative;
  }

  .arrows {
    /* top: 21rem; */
    left: 50%;
    transform: translate(-50%, 130%);
  }

  .arrows > img {
    width: 1.2rem;
    height: 1.2rem;
  }
}
