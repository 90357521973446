.hero {
  display: flex;
  justify-content: space-between;
}

.left-h {
  padding: 2rem;
  padding-top: 1.5rem;
  flex: 3;
  display: flex;
  gap: 2rem;
  flex-direction: column;
}

.right-h {
  flex: 1;
}

.the-best-ad {
  margin-top: 4rem;
  background: rgb(98, 98, 98);
  border-radius: 4rem;
  width: fit-content;
  padding: 20px 13px;
  text-transform: uppercase;
  color: white;
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: flex-start;
}

.the-best-ad > div {
  position: absolute;
  background: var(--orange);
  width: 4rem;
  height: 80%;
  left: 8px;
  border-radius: 4rem;
}

.the-best-ad > span {
  z-index: 1;
}

.hero-text {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  text-transform: uppercase;
  color: #fff;
  font-size: 4.5rem;
  font-weight: blod;
  text-overflow: inherit;
}

.hero-text > div:nth-of-type(3) {
  font-size: 1rem;
  font-weight: 200;
  text-transform: none;
  letter-spacing: 1px;
  width: 80%;
}

.figures {
  display: flex;
  color: white;
  gap: 2rem;
  font-size: 1rem;
}

.figures > div {
  display: flex;
  flex-direction: column;
}

.figures > div > span:first-of-type {
  font-size: 2rem;
}

.figures > div > span:nth-of-type(2) {
  opacity: 0.5;
  text-transform: uppercase;
}

.btns {
  display: flex;
  gap: 1rem;
}

.btns button {
  border: none;
  outline: none;
  width: 8rem;
  border: 2px solid var(--orange);
  background-color: var(--orange);
  color: white;
}

.btns button:nth-of-type(2) {
  background: transparent;
}

.right-h {
  flex: 1;
  background-color: var(--orange);
  position: relative;
}

.right-h > .btn {
  position: absolute;
  right: 3rem;
  top: 2rem;
  color: black;
}

.heart-rate {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  background-color: var(--darkGrey);
  width: fit-content;
  padding: 1rem;
  align-items: start;
  border-radius: 5px;
  position: absolute;
  right: 4rem;
  top: 7rem;
}

.heart-rate > img {
  width: 2rem;
}

.heart-rate > span:nth-of-type(1) {
  color: var(--gray);
}

.heart-rate > span:nth-of-type(2) {
  color: white;
  font-size: 1.5rem;
}

.hero-image {
  position: absolute;
  top: 10rem;
  right: 8rem;
  width: 23rem;
}

.hero-image-back {
  position: absolute;
  top: 4rem;
  right: 20rem;
  z-index: -1;
  width: 15rem;
}

.calories {
  display: flex;
  padding: 0.8rem;
  gap: 2rem;
  background: var(--caloryCard);
  width: fit-content;
  border-radius: 5px;
  position: absolute;
  top: 32rem;
  right: 28rem;
}

.calories > div {
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.calories > div > span:first-of-type {
  font-size: 1rem;
  color: var(--gray);
}

.calories > img {
  width: 3rem;
}
.calories > div > span:nth-of-type(2) {
  font-size: 1.5rem;
}

.blur-h {
  width: 22rem;
  height: 30rem;
  left: 0;
}

@media screen and (max-width: 768px) {
  .btns {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
  .hero {
    flex-direction: column;
  }
  .blur-h {
    width: 14rem;
  }

  .the-best-ad {
    text-align: center;
    margin-top: -2rem;
    align-self: center;
  }

  .hero-text {
    font-size: xx-large;
    text-align: left;
    align-items: center;
    justify-content: center;
    text-align: center;
  }

  .figures {
    display: flex;
    gap: 1.5rem;
    justify-content: space-around;
    align-items: center;
    text-align: center;
    font-size: small;
  }

  .figures > div > span:nth-of-type(1) {
    font-size: large;
  }

  .right-h {
    position: relative;
    background: none;
  }

  .right-h > :nth-child(1) {
    display: none;
  }

  .heart-rate {
    top: 75px;
  }

  .hero-image {
    position: relative;
    width: 90%;
    transform: translateX(50%);
    margin-bottom: 5rem;
  }

  .hero-image-back {
    width: 15rem;
    left: 2rem;
    top: 0rem;
    opacity: 0.7;
  }

  .calories {
    position: relative;
    top: 5rem;
    left: 2rem;
  }

  .calories > img {
    width: 2rem;
  }

  .blur-h {
    display: none;
  }
}
