.join {
  display: flex;
  flex-direction: row;
  margin-left: 2rem;
}

.left-j {
  color: white;
}

.left-j > div > span {
  font-weight: bold;
  position: relative;
  font-size: 3rem;
}

.left-j::before {
  content: "";
  position: absolute;
  background: orange;
  width: 11rem;
  transform: translateY(-0.3rem);
  height: 4px;
  border-radius: 20%;
}

.right-j {
  display: flex;
  justify-content: center;
  align-items: flex-end;
}

.email-container > .btn-join {
  background: var(--orange);
  color: white;
}

.email-container > :nth-child(1) {
  background: transparent;
  color: var(--lightgray);
  border: none;
  outline: none;
}

::placeholder {
  color: var(--lightgray);
}

.email-container {
  display: flex;
  gap: 3rem;
  background: gray;
  padding: 1rem 2rem;
  position: relative;
  left: 10rem;
}

@media screen and (max-width: 768px) {
  .join {
    flex-direction: column;
    margin-left: 0.5rem;
  }
  .left-j > div > span {
    font-size: x-large;
    text-align: left;
  }
  .left-j::before {
    width: 6rem;
  }

  .right-j > .email-container {
    left: -0.2rem;
    top: 1rem;
    padding: 0.5rem;
    gap: 0.2rem;
  }
}
